import React, { useState } from 'react';
import { Card, CardBody, CardHeader, FormGroup, Label, Col, Input } from 'reactstrap';
import { calcWeekly, calcFortnightly, calcQuarterly, calcAnnually } from '../../util/Conversions';

export const IncomeCategoryItem = (props) => {

    const [frequency, setFrequency] = useState('monthly');

    const handleFrequencyUpdate = (event) => {
        let val = event.target.value;
        setFrequency(val);
    }

    const calcAmount = (monthlyAmount) => {

        if (frequency === 'Weekly') {
            return Math.round(calcWeekly('Monthly', monthlyAmount));
        } else if (frequency === 'Fortnightly') {
            return Math.round(calcFortnightly('Monthly', monthlyAmount));
        } else if (frequency === 'Quarterly') {
            return Math.round(calcQuarterly('Monthly', monthlyAmount));
        } else if (frequency === 'Annually') {
            return Math.round(calcAnnually('Monthly', monthlyAmount));
        } else {
            return monthlyAmount;
        }

    };

    return (
        props.isOpen &&
        <Card className="manage-category-item">
            <CardHeader>
                {props.category.name}     
                <Input type="select" bsSize="sm" onChange={handleFrequencyUpdate} style={{ width: "110px" }} defaultValue={"Monthly"}>
                    <option>Annually</option>
                    <option>Quarterly</option>
                    <option>Monthly</option>
                    <option>Fortnightly</option>
                    <option>Weekly</option>
                </Input>

            </CardHeader>
            <CardBody>
                {props.category.incomeItemTypes.map(t =>

                    <FormGroup row key={t.code}>
                        <Label for={'amount_' + t.code} sm={7} xs={12} size="sm">{t.name}</Label>
                        <Col sm={5} xs={12} style={{ textAlign: 'right' }}>
                            $ {calcAmount(t.amountMonthly)}
                        </Col>
                    </FormGroup>
                )}     
                <FormGroup row>
                    <Label sm={7} xs={12} size="sm">Total</Label>
                    <Col sm={5} xs={12} style={{ textAlign: 'right' }}>
                        $ {calcAmount(props.category.totalMonthly)}
                    </Col>
                </FormGroup>   
            </CardBody>
        </Card>
    )

}
