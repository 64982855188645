import React from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Col, Input, CustomInput, Button } from 'reactstrap';

export const SubmissionDetails = (props) => {

    const handleUpdate = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        props.onUpdate(name, value);
    }

    const handleNumberUpdate = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        props.onUpdate(name, Number(value));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit();
    }

    return (
        <Card className="submission-details">
            <CardHeader><h3>Your Details</h3></CardHeader>
            <CardBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup row>
                        <Label for="name" md={4} sm={12} size="sm">Name</Label>
                        <Col md={8} sm={12}>
                            <Input name="name" id="name" placeholder="" bsSize="sm" onChange={handleUpdate} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="phone" md={4} sm={12} size="sm">Phone</Label>
                        <Col md={8} sm={12}>
                            <Input name="phone" id="phone" placeholder="" bsSize="sm" onChange={handleUpdate} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="email" md={4} sm={12} size="sm">Email</Label>
                        <Col md={8} sm={12}>
                            <Input name="email" type="email" id="email" placeholder="" bsSize="sm" onChange={handleUpdate} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="current_home_value" md={6} sm={12} size="sm">Current Home Value</Label>
                        <Col md={6} sm={12}>
                            <Input name="currentHomeValue" type="number" id="current_home_value" placeholder="" bsSize="sm" onChange={handleNumberUpdate} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="home_loan_balance" md={6} sm={12} size="sm">Home Loan Balance Owing</Label>
                        <Col md={6} sm={12}>
                            <Input name="homeLoanBalanceOwing" type="number" id="home_loan_balance" placeholder="" bsSize="sm" onChange={handleNumberUpdate} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="home_loan_interest_rate" md={6} sm={12} size="sm">Home Loan % Interest Rate</Label>
                        <Col md={6} sm={12}>
                            <Input name="homeLoanInterestRate" type="number" step="0.1" id="home_loan_interest_rate" placeholder="" bsSize="sm" onChange={handleNumberUpdate} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Label>Home Loan Type</Label>
                        <div>
                            <CustomInput type="radio" id="home_loan_type_variable" name="homeLoanType" label="Variable" onChange={handleUpdate} value="variable" />
                            <CustomInput type="radio" id="home_loan_type_fixed" name="homeLoanType" label="Fixed" onChange={handleUpdate} value="fixed" />
                        </div>
                    </FormGroup>
                    <Button color="primary" size="lg" block>Submit</Button>
                </Form>
            </CardBody>
        </Card>
    )

}