import React, { useState, useMemo } from 'react';
import { Card, CardBody, CardHeader, Progress, Input } from 'reactstrap';
import { calcWeekly, calcFortnightly, calcQuarterly, calcAnnually } from './util/Conversions';

import {
    PieChart, Pie, Cell
} from 'recharts';

const COLORS = {
    financial_commitments: '#12296c',
    home: '#f9a127',
    utilities: '#00b1ec',
    education: '#652f8e',
    health: '#ee386c',
    shopping: '#84b840',
    transport: '#ea1f27',
    entertainment: '#1b75ba',
    eating_out: '#d5dd26'
}

export const BudgetSummary = (props) => {

    const [frequency, setFrequency] = useState('monthly');

    const handleFrequencyUpdate = (event) => {
        let val = event.target.value;
        setFrequency(val);
    }

    const calcAmount = (monthlyAmount) => {

        if (frequency === 'Weekly') {
            return Math.round(calcWeekly('Monthly', monthlyAmount));
        } else if (frequency === 'Fortnightly') {
            return Math.round(calcFortnightly('Monthly', monthlyAmount));
        } else if (frequency === 'Quarterly') {
            return Math.round(calcQuarterly('Monthly', monthlyAmount));
        } else if (frequency === 'Annually') {
            return Math.round(calcAnnually('Monthly', monthlyAmount));
        }   else {
            return monthlyAmount;
        }

    };


    const handleOnPieEnter = () => {
 
    }

    const balance = () => {
        return incomeTotal() - expenseTotal;
    };

    const balanceBarValue = () => {
        let bal = balance();
        if (bal < 0) {
            return 0;
        } else {
            return (bal / incomeTotal()) * 100;
        }
    };

    const isDeficit = () => {
        let bal = balance();
        return bal < 0;
    };


    const expenseTotal = useMemo(() => {

        let total = 0;
        props.expenseSummaryData.forEach(function (d) {
            total = total + Number(d.value);
        });

        return Math.round(total);

    }, [props.expenseSummaryData]);


    const incomeTotal = () => {
        return Math.round(props.incomeTotal);
    };

    return (
        <Card className="budget-summary">
            <CardHeader >
                <h3>Summary</h3>
                <Input type="select" bsSize="sm" onChange={handleFrequencyUpdate} style={{ width: "110px" }} defaultValue={"Monthly"}>
                    <option>Annually</option>
                    <option>Quarterly</option>
                    <option>Monthly</option>
                    <option>Fortnightly</option>
                    <option>Weekly</option>
                </Input>
            </CardHeader>
            <CardBody>

                <div className="budget-summary-section">
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td colSpan="2" style={{ paddingTop: '4px' }}><h4>Income</h4></td>
                                <td style={{ textAlign: 'right', paddingTop: '4px' }} >${calcAmount(incomeTotal())}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="divider"></div>
                <div className="budget-summary-section">
                    <h4>Outgoings</h4>
                    <PieChart width={200} height={200} onMouseEnter={handleOnPieEnter}>
                        <Pie
                            data={props.expenseSummaryData}
                            cx={100}
                            cy={100}
                            innerRadius={40}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                        >
                            {
                                props.expenseSummaryData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />)
                            }
                        </Pie>
                    </PieChart>

                    <table style={{ width: '100%' }}>
                        <tbody>
                            {props.expenseSummaryData.map(d =>
                                <tr key={d.name}>
                                    <td style={{ width: '50px', padding: '4px' }}>
                                        <svg height="30" width="30">
                                            <circle cx="15" cy="15" r="15" stroke="black" strokeWidth="0" fill={COLORS[d.name]} />
                                        </svg>
                                    </td>
                                    <td>
                                        {d.label}
                                    </td>
                                    <td style={{ textAlign: 'right' }} >
                                        ${calcAmount(d.value)}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan="2" style={{ paddingTop: '4px' }}><h4>Total Outgoings</h4></td>
                                <td style={{ textAlign: 'right', paddingTop: '4px' }} >${calcAmount(expenseTotal)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="divider"></div>
                <div className="budget-summary-section">
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td colSpan="2" style={{ paddingTop: '4px' }}><h4>What's Left</h4></td>
                                <td style={{ textAlign: 'right', paddingTop: '4px' }} >${ calcAmount(balance()) }</td>
                            </tr>
                        </tbody>
                    </table>
                    <Progress multi style={{ backgroundColor: isDeficit() ? 'red' : 'lightgray' }}>
                        <Progress bar color="success" value={balanceBarValue()}>{`${Math.round(balanceBarValue())}%`}</Progress>
                    </Progress>
                </div>

            </CardBody>
        </Card>

    )


}