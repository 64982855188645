
export const calcWeekly = (fromFrequency, amount) => {

    let weekly = 0;

    switch (fromFrequency) {
        case "Annually":
            weekly = amount / 52;
            break;
        case "Quarterly": 
            weekly = amount * 4 / 52;
            break;
        case "Monthly": // Month to Week
            weekly = amount * 12 / 52;
            break;
        case "Fortnightly":
            weekly = amount * 26 / 52
            break;
        case "Weekly":
            weekly = amount;
            break;
        default:
            weekly = amount;
            break;
    }

    return weekly;
}


export const calcFortnightly = (fromFrequency, amount) => {

    let fortnightly = 0;

    switch (fromFrequency) {
        case "Annually":
            fortnightly = amount / 26;
            break;
        case "Quarterly":
            fortnightly = amount * 4 / 26;
            break;
        case "Monthly":
            fortnightly = amount * 12 / 26;
            break;
        case "Fortnightly":
            fortnightly = amount;
            break;
        case "Weekly":
            fortnightly = amount * 52 / 26;
            break;
        default:
            fortnightly = amount;
            break;
    }

    return fortnightly;
}


export const calcMonthly = (fromFrequency, amount) => {

    let monthly = 0;

    switch (fromFrequency) {
        case "Annually":
            monthly = amount / 12;
            break;
        case "Quarterly":
            monthly = amount * 4 / 12;
            break;
        case "Monthly":
            monthly = amount;
            break;
        case "Fortnightly":
            monthly = amount * 26 / 12;
            break;
        case "Weekly":
            monthly = amount * 52 / 12;
            break;
        default:
            monthly = amount;
            break;
    }

    return monthly;
}


export const calcQuarterly = (fromFrequency, amount) => {

    let quarterly = 0;

    switch (fromFrequency) {
        case "Annually":
            quarterly = amount / 4;
            break;
        case "Quarterly":
            quarterly = amount;
            break;
        case "Monthly":
            quarterly = amount * 12 / 4;
            break;
        case "Fortnightly":
            quarterly = amount * 26 / 4;
            break;
        case "Weekly":
            quarterly = amount * 52 / 4;
            break;
        default:
            quarterly = amount;
            break;
    }

    return quarterly;
}

export const calcAnnually = (fromFrequency, amount) => {

    let quarterly = 0;

    switch (fromFrequency) {
        case "Annually":
            quarterly = amount;
            break;
        case "Quarterly":
            quarterly = amount * 4;
            break;
        case "Monthly":
            quarterly = amount * 12;
            break;
        case "Fortnightly":
            quarterly = amount * 26;
            break;
        case "Weekly":
            quarterly = amount * 52;
            break;
        default:
            quarterly = amount;
            break;
    }

    return quarterly;
}
