import React, { Component } from 'react';
import $ from 'jquery';
import { Form, Container } from 'reactstrap';
import { ExpenseCategoryItem } from './category-item/ExpenseCategoryItem';
import { IncomeCategoryItem } from './category-item/IncomeCategoryItem';
import { BudgetSummary } from './BudgetSummary';
import { SubmissionDetails } from './SubmissionDetails';
import { calcMonthly } from './util/Conversions';

export class BudgetSubmission extends Component {
    static displayName = BudgetSubmission.name;

    constructor(props) {
        super(props);
        this.state = {
            metadata: null,
            openExpenseCat: null,
            loading: true,
            expenseItems: [],
            expenseSummaryData: [],
            incomeItems: {},
            details: {}
        };
    }

    componentDidMount() {
        this.loadBudgetSubmissionMetadata();     
    }


    handleToggle = (catCode) => {
        if (this.state.openExpenseCat != catCode) {
            this.setState({ openExpenseCat: catCode });
        } else {
            this.setState({ openExpenseCat: null });
        }
    }

    handleIncomeItemsUpdate = (catCode, itemValues) => {

        // Update the collection of all income items
        const itemValuesClone = { ...this.state.incomeItems };
        var incomeItems = { incomeItems: Object.assign(itemValuesClone, itemValues) };
        this.setState(incomeItems);

    };

    handleExpenseItemsUpdate = (catCode,catName, itemValues) => {


        // Get the total for this category
        let total = 0;
        for (const [key, value] of Object.entries(itemValues)) {
            total = total + calcMonthly(value.frequency, Number(value.amount));
        }

        // Update the category summary data
        const expenseSummaryDataClone = [...this.state.expenseSummaryData];

        var catExpenseSummary = expenseSummaryDataClone.find(x => x.name === catCode);
        if (catExpenseSummary) {
            catExpenseSummary.value = Math.round(total);
        } else {
            expenseSummaryDataClone.push({
                name: catCode,
                label: catName,
                value: Math.round(total)
            });
        }

        this.setState({ expenseSummaryData: expenseSummaryDataClone });


        const expenseItemsClone = [...this.state.expenseItems];

        var catExpenseItems = expenseItemsClone.find(x => x.name === catCode);
        if (catExpenseItems) {
            const itemValuesClone = { ...catExpenseItems.items };
            var newItems = Object.assign(itemValuesClone, itemValues) ;
            catExpenseItems.items = newItems;
        } else {
            expenseItemsClone.push({
                name: catCode,
                items: itemValues
            });
        }
        this.setState({ expenseItems: expenseItemsClone });


    };

    handleDetailsUpdate = (name, value) => {
        var detailsClone = { ...this.state.details };
        detailsClone[name] = value;
        this.setState({ details: detailsClone });
    };

    handleSubmit = () => {

        var submitData = {
            income: [],
            expenses: [],
            details: this.state.details
        }

        for (const [key, value] of Object.entries(this.state.incomeItems)) {
            submitData.income.push({ code: key, value: value });
        }

        this.state.expenseItems.forEach(x => {
            let itemTypeCode = x.name;

            for (const [code, value] of Object.entries(x.items)) {
                submitData.expenses.push({ itemTypeCode, code: code, value: value });
            }
        });

        let history = this.props.history;

        $.ajax({
            method: "POST",
            url: 'api/budgetsubmission/new',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(submitData),
            dataType: "json"
        })
            .done(function (data) {
                history.push("/submission-success")
            })
            .fail(function (xhr) {
                alert("fail");
            });

    };

    static renderSubmissionForm(state, handleToggle, handleExpenseItemsUpdate, handleIncomeItemsUpdate, handleDetailsUpdate, handleSubmit) {

        const incomeTotal = (imcomeItems) => {
            let total = 0;
            for (const [key, value] of Object.entries(imcomeItems)) {
                total = total + calcMonthly(value.frequency, Number(value.amount));
            }
            return Math.round(total);
        };

        const postHeightChange = () => {
            var body = document.body,
                html = document.documentElement;

            var height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);

            if (window.parent) {
                console.log('Posting height ' + height + ' to parent');
                window.parent.postMessage(height, '*');
            }
        }

        const handleTogglePanelComplete = () => {
            postHeightChange();
        }


        return (
            <Container>
            <React.Fragment>
                    <div className="row" style={{ marginTop: 8 }}>
                    <div className="col-md-7">
                        <Form>
                            <div className="income-container">
                                    {state.metadata.incomeCategories.map(inCat =>
                                        <IncomeCategoryItem key={inCat.code} category={inCat} isOpen={state.openExpenseCat === inCat.code} onToggle={handleToggle} onUpdate={handleIncomeItemsUpdate} onTogglePanelComplete={handleTogglePanelComplete}/>
                                )}

                            </div>
                            <div className="expenses-container">
                                <h3 className="category-parent-heading">Outgoings</h3>
                                {state.metadata.expenseCategories.map(expCat =>
                                    <ExpenseCategoryItem key={expCat.code} category={expCat} isOpen={state.openExpenseCat === expCat.code} onToggle={handleToggle} onUpdate={handleExpenseItemsUpdate} onTogglePanelComplete={handleTogglePanelComplete} />
                                )}
                            </div>

                        </Form>
                    </div>
                    <div className="col-md-5">
                        {state.expenseSummaryData.length > 0 &&
                                <BudgetSummary expenseSummaryData={state.expenseSummaryData} incomeTotal={incomeTotal(state.incomeItems)} />
                        }
                        <SubmissionDetails onUpdate={handleDetailsUpdate} onSubmit={handleSubmit} />
                    </div>
                </div>
                </React.Fragment>
            </Container>
        );
    }

    static renderLoader() {
        return (
            <div>Loading</div>
        );
    }


    render() {

        let contents = this.state.loading
            ? BudgetSubmission.renderLoader()
            : BudgetSubmission.renderSubmissionForm(this.state, this.handleToggle, this.handleExpenseItemsUpdate, this.handleIncomeItemsUpdate, this.handleDetailsUpdate, this.handleSubmit);

        return contents;

    }

    async loadBudgetSubmissionMetadata() {
        const response = await fetch('api/budgetsubmission/metadata');
        const data = await response.json();

        this.setState({ metadata: data, loading: false });
    }


}
