import React from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';

export const HomeLeftMenu = (props) => {

    return (
        <div className="left-menu">
            <img alt="" style={{ marginBottom: '20px' }} src="./newbridge-logo.svg" />
            <h1>Budget Planner</h1>
            <div style={{ width: '145px' }}>
                Click on the entries to view customers budget details
            </div>
        </div>
    )

}