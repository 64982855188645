import React from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';

export const SubmissionLeftMenu = (props) => {

    return (
        <div className="left-menu">
            <h1>Budget Planner</h1>

            <h2>Income</h2>
            <Nav vertical pills>
                <NavItem>               
                    <NavLink href="#" className="text-light" active={props.displayIncome} onClick={() => { props.onSelectIncomeOpen(); }} >
                        <img alt="" src={`./icons/income.svg`}></img>
                        <span>All</span>
                        <div style={{ clear: 'both' }}></div>
                    </NavLink>
                </NavItem>
            </Nav>


            <h2>Outgoings</h2>
            <Nav vertical pills>
                {props.expenseCategories.map(expCat =>
                    <NavItem key={expCat.code}>
                        <NavLink href="#" className="text-light" active={props.openExpenseCat === expCat.code} onClick={() => { props.onSelectExpenseCategory(expCat.code); }}><img alt="" src={`./icons/${expCat.code}.svg`}></img><span>{expCat.name}</span><div style={{ clear: 'both' }}></div></NavLink>
                    </NavItem>
                )}
            </Nav>


            <h2>Accounts</h2>
            <Nav vertical pills>
                <NavItem>
                    <NavLink href="#" className="text-light" active={props.displayExpenseTags} onClick={() => { props.onSelectTagExpensesOpen(); }} ><img alt="" src={`./icons/account-buckets.svg`}></img><span>Buckets</span><div style={{ clear: 'both' }}></div></NavLink>
                </NavItem>
            </Nav>
        </div>
    )

}