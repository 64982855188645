import React, { Component } from 'react';
import { Container, NavItem, NavLink, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';


import axios from 'axios';
import { BudgetSummary } from '../BudgetSummary';
import { LoginMenu } from '../api-authorization/LoginMenu';
import { ExpenseCategoryItem } from './category-item/ExpenseCategoryItem';
import { IncomeCategoryItem } from './category-item/IncomeCategoryItem';
import { Details } from './Details';
import { SubmissionLeftMenu } from './SubmissionLeftMenu';
import { TagExpenses } from './TagExpenses';


export class Submission extends Component {
    static displayName = Submission.name;

    constructor(props) {
        super(props);
        this.state = {
            expenseCategories: null,
            incomeCategories: null,
            displayIncome: true,
            openExpenseCat: null,
            displayExpenseTags: false,
            loading: true,
            expenseItems: {},
            expenseSummaryData: [],
            incomeItems: {},
            details: {},
            tagExpenses: []
        };
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        this.load(params.submissionId);
    }

    handleIncomeOpen = () => {
        this.setState({ displayIncome: true, openExpenseCat: null, displayExpenseTags: false  })
    }

    handleExpenseCatOpen = (catCode) => {
        this.setState({ displayIncome: false, openExpenseCat: catCode, displayExpenseTags: false });
    }

    handleTagExpensesOpen = () => {
        this.setState({ displayIncome: false, openExpenseCat: null, displayExpenseTags: true  });
    }

    static renderSubmissionForm(state, handleExpenseCatOpen, handleTagExpensesOpen, handleIncomeOpen) {

        const incomeTotal = (incomeItems) => {
            console.log(incomeItems);
            let total = 0;
            for (const [key, value] of Object.entries(incomeItems)) {
                total = total + Number(value);
            }
            return Math.round(total);
        };


        return (
            <Container fluid={true}>
                <SubmissionLeftMenu
                    expenseCategories={state.expenseCategories}
                    onSelectExpenseCategory={handleExpenseCatOpen}
                    onSelectTagExpensesOpen={handleTagExpensesOpen}
                    onSelectIncomeOpen={handleIncomeOpen}
                    displayExpenseTags={state.displayExpenseTags}
                    openExpenseCat={state.openExpenseCat}
                    displayIncome={state.displayIncome} />
                <div className="col-sm-12 main">
                    <div>
                    <Nav className="top-menu">
                        <NavItem>
                            <NavLink tag={Link} className="text-light home-link" to="/manage/submissions"><img alt="" src="./icons/home.svg"/> Home</NavLink>
                        </NavItem>

                        <LoginMenu/>
                        </Nav>
                        <div style={{clear: 'both'}}></div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <Details details={state.details} />
                            </div>
                        </div>
                    </div>
                    {(state.displayIncome || state.openExpenseCat) &&
                        <div className="row">
                            {state.displayIncome &&
                                <div className="col-lg-7 col-xs-12">
                                    <div className="expenses-container">
                                        {state.incomeCategories.map(inCat =>
                                            <IncomeCategoryItem key={inCat.code} category={inCat} isOpen={true} />
                                        )}
                                    </div>
                                </div>
                            }
                            {state.openExpenseCat != null &&
                                <div className="col-lg-7 col-xs-12">
                                <div className="expenses-container">
                                    {state.expenseCategories.map(expCat =>
                                        <ExpenseCategoryItem key={expCat.code} category={expCat} isOpen={state.openExpenseCat && state.openExpenseCat === expCat.code} />
                                    )}
                                </div>
                            </div>
                        }
                            <div className="col-lg-5 col-xs-12">
                                    {state.expenseSummaryData.length > 0 &&
                                        <BudgetSummary expenseSummaryData={state.expenseSummaryData} incomeTotal={incomeTotal(state.incomeItems)} />
                                    }
                            </div>
             
                        </div>
                    }

                    {state.displayExpenseTags &&
                    <div className="row">
                        <div className="col-lg-4">
                            <TagExpenses key={state.tagExpenses[0].tagCode} tagExpenses={state.tagExpenses[0]} />                           
                        </div>
                        <div className="col-lg-4">
                            <TagExpenses key={state.tagExpenses[2].tagCode} tagExpenses={state.tagExpenses[2]} />
                        </div>
                        <div className="col-lg-4">
                            <TagExpenses key={state.tagExpenses[1].tagCode} tagExpenses={state.tagExpenses[1]} />
                            <TagExpenses key={state.tagExpenses[3].tagCode} tagExpenses={state.tagExpenses[3]} />
                            <TagExpenses key={state.tagExpenses[4].tagCode} tagExpenses={state.tagExpenses[4]} />
                        </div>
                    </div>           
                    }
                </div>
            </Container>
        );
    }

    static renderLoader() {
        return (
            <div>Loading</div>
        );
    }

    render() {

        let contents = this.state.loading
            ? Submission.renderLoader()
            : Submission.renderSubmissionForm(this.state, this.handleExpenseCatOpen, this.handleTagExpensesOpen, this.handleIncomeOpen);

        return contents;
    }

    async load(id) {
        let metadata = await this.loadMetadata();
        await this.loadSubmission(id);
        await this.loadTagExpenses(id);

        let expenseCategories = metadata.expenseCategories.map((cat) => {
            const catClone = { ...cat };
            let totalMonthly = 0;
            catClone.expenseItemTypes.forEach((itemType) => {
                itemType.amountMonthly = this.state.expenseItems[itemType.code];
                if (!itemType.amountMonthly) {
                    itemType.amountMonthly = 0
                }

                totalMonthly = totalMonthly + itemType.amountMonthly;
            });
            catClone.totalMonthly = totalMonthly;

            return catClone;
        });

        let incomeCategories = metadata.incomeCategories.map((cat) => {
            const catClone = { ...cat };
            let totalMonthly = 0;
            catClone.incomeItemTypes.forEach((itemType) => {
                itemType.amountMonthly = this.state.incomeItems[itemType.code];
                if (!itemType.amountMonthly) {
                    itemType.amountMonthly = 0
                }

                totalMonthly = totalMonthly + itemType.amountMonthly;
            });
            catClone.totalMonthly = totalMonthly;

            return catClone;
        });

        this.setState({
            expenseCategories: expenseCategories,
            incomeCategories: incomeCategories
        });

        this.setState({ loading: false });
    }

    async loadSubmission(id) {

        try {
            const response = await axios.get(`api/budgetsubmission/details/${id}`);

            let expenseSummaryData = response.data.expenseCategoryTotals.map((d) => {
                return {
                    name: d.code,
                    label: d.name,
                    value: d.monthlyTotal
                }              
            });

            let incomeItems = {};
            response.data.incomeMonthly.forEach((d) => {
                incomeItems[d.code] = d.amountMonthly;
            });

            let expenseItems = {};
            response.data.expensesMonthly.forEach((d) => {
                expenseItems[d.code] = d.amountMonthly;
            });

            this.setState({ expenseSummaryData: expenseSummaryData });

            this.setState({
                details: response.data.details,
                incomeItems: incomeItems,
                expenseItems: expenseItems
            });

        } catch (error) {
            console.error(error);
        }
    }


    async loadTagExpenses(id) {

        try {
            const response = await axios.get(`api/budgetsubmission/tag-expenses/${id}`);

            this.setState({
                tagExpenses: response.data
            });

        } catch (error) {
            console.error(error);
        }
    }

    async loadMetadata() {

        try {
            const response = await axios.get(`api/budgetsubmission/metadata`);
            return response.data;

        } catch (error) {
            console.error(error);
        }
    }


}
