import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';

import { BudgetSubmission } from './components/BudgetSubmission'

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { SubmissionSuccess } from './components/SubmissionSuccess';
import { Submissions } from './components/manage/Submissions';
import { Submission } from './components/manage/Submission';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={BudgetSubmission} />
                    <Route exact path='/submission-success' component={SubmissionSuccess} />

                    <AuthorizeRoute path='/manage/submissions/:submissionId' component={Submission} />
                    <AuthorizeRoute path='/manage/submissions' component={Submissions} />

                    <AuthorizeRoute path='/fetch-data' component={FetchData} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                </Switch>
            </Layout>
        );
    }
}
