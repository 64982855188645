import React, { Component } from 'react';

export default class GridCommandsRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentViewMethod = this.invokeParentViewMethod.bind(this);
        this.invokeParentDeleteMethod = this.invokeParentDeleteMethod.bind(this);
        this.invokeParentExportMethod = this.invokeParentExportMethod.bind(this);
    }

    invokeParentViewMethod() {
        this.props.context.componentParent.handleViewCommmand(
            this.props.node.data.id
        );
    }

    invokeParentDeleteMethod() {
        this.props.context.componentParent.handleDeleteCommmand(
            this.props.node.data.id
        );
    }

    invokeParentExportMethod() {
        this.props.context.componentParent.handleExportCommmand(
            this.props.node.data.id
        );
    }

    render() {
        return (
            <span>
                <button
                    onClick={this.invokeParentViewMethod}
                    className="btn btn-info view-button"
                >
                    View
                </button>
                <button
                    onClick={this.invokeParentDeleteMethod}
                    className="btn btn-info delete-button"
                >
                    Delete
                </button>

                <button
                    onClick={this.invokeParentExportMethod}
                    className="btn btn-info export-button"
                >
                    Export
                </button>
            </span>
        );
    }
}