import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Nav, Container } from 'reactstrap';
import $ from 'jquery';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


import GridCommandsRenderer from './ag-grid-cell-renderers/GridCommandsRenderer';
import { LoginMenu } from '../api-authorization/LoginMenu';

import { HomeLeftMenu } from './HomeLeftMenu';


export class Submissions extends Component {
    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            columnDefs: [{
                headerName: "Email", field: "email", sortable: true
            }, {
                headerName: "Name", field: "name", sortable: true
            }, {
                headerName: "Phone", field: "phone", sortable: true
            }, {
                    headerName: "Date Submitted", field: "submissionDate", sortable: true,
                    type: ['dateColumn', 'nonEditableColumn'],
                    valueFormatter: (params) => { return new Date(params.value).toLocaleString(); }
            }, {
                headerName: "Actions:", field: "id", cellRenderer: 'gridCommandsRenderer', width: 250
            }],
            rowData: []
        }
    }

    componentDidMount() {
        fetch('/api/budgetsubmission/list')
            .then(result => result.json())
            .then(rowData => this.setState({ rowData }))
    }

    handleDeleteCommmand = id => {

        if (window.confirm('Delete Submission?')) {

            let me = this;

            $.ajax({
                method: "POST",
                url: `api/budgetsubmission/delete/${id}`,
                contentType: 'application/json; charset=utf-8',
                dataType: "json"
            })
                .done(function (data) {
                    fetch('/api/budgetsubmission/list')
                        .then(result => result.json())
                        .then(rowData => me.setState({ rowData }))
                })
                .fail(function (xhr) {
                    alert("fail");
                });
        }

    };

    handleViewCommmand = id => {
        this.props.history.push(`/manage/submissions/${id}`);
    }

    handleExportCommmand = id => {
        window.location.href = `api/budgetsubmission/excel/${id}`;
    }

    handleExportAllCommand = () => {
        window.location.href = `api/budgetsubmission/excel/`;
    }

    render() {
        return (
            <Container fluid={true}>

                <HomeLeftMenu/>
                <div className="col-sm-12 main">
                    <div>
                        <h1 style={{float: 'left', padding: '15px 0 0 4px'}}>Website Enquiries</h1>
                        <Nav className="top-menu">
                            <LoginMenu />
                        </Nav>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                        <button
                            onClick={this.handleExportAllCommand}
                            className="btn btn-info export-button"
                        >Export All</button>
                    </div>
                    <div
                        className="ag-theme-alpine"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.rowData}
                            context={{ componentParent: this }}
                            frameworkComponents={{ gridCommandsRenderer: GridCommandsRenderer }}
                            domLayout={'autoHeight'}
                        >

                        </AgGridReact>
                    </div>
                </div>

            </Container>
        );
    }
}

