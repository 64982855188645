import React from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import successIcon from './success-icon.svg';

export const SubmissionSuccess = (props) => {


    return (
        <Container>
        <Card className="submission-success">
            <CardBody>
                <img src={successIcon} alt="" />
                <h3>Thank you!</h3>
                <h4>Your details were successfully sent</h4>
                <p>One of our friendly consultants will be in contact with you soon</p>
            </CardBody>
            </Card>
        </Container>
    )

}