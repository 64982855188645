import React, { useState, useMemo, useRef } from 'react';
import { Collapse, Card, CardBody, CardHeader, FormGroup, Label, Col, Input } from 'reactstrap';
import { calcMonthly } from '../util/Conversions';
import './ExpenseCategoryItem.css';

export const ExpenseCategoryItem = (props) => {

    const headerRef = useRef(null);

    const handleClick = () => {
        props.onToggle(props.category.code);   
    }

    let initialValues = {};
    props.category.expenseItemTypes.forEach(t => {
        initialValues[t.code] = {
            amount: 0,
            frequency: 'Monthly'
        }
    });


    const onEntered = () => {
        headerRef.current.scrollIntoView({ behavior: 'smooth' });
        props.onTogglePanelComplete();
    };

    const onExited = () => {
        props.onTogglePanelComplete();
    };

  
    const [itemValues, setItemValues] = useState(initialValues);


    const handleAmountUpdate = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        const itemValuesClone = { ...itemValues};
        itemValuesClone[nam].amount = Number(val);
        setItemValues(itemValuesClone);

        props.onUpdate(props.category.code, props.category.name, itemValuesClone);
    }

    const handleFrequencyUpdate = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        const itemValuesClone = { ...itemValues };
        itemValuesClone[nam].frequency = val;
        setItemValues(itemValuesClone);

        props.onUpdate(props.category.code, props.category.name, itemValuesClone);
    }

    const subtotalDisplay = useMemo(() => {

        let total = 0;
        for (const [key, value] of Object.entries(itemValues)) {
            total = total + calcMonthly(value.frequency, Number(value.amount));
        }

        return `$${Math.round(total)}`;

    }, [itemValues]);

    return (

        <Card className="expense-category-item" innerRef={headerRef}>
            <CardHeader onClick={handleClick} className={'budget-item-header'}  >
                <div className="float-left" style={{ marginLeft: '-10px' }}>
                    <img alt="" src={`./icons/m-${props.category.code}.svg`}></img>
                    <span>{props.category.name}</span>
                </div>
    
                <div className="float-right">
                    <img alt="" src="./icons/expand-icon.svg" style={{ marginLeft: '6px', display: props.isOpen ? 'none' : 'block' }} />
                    <img alt="" src="./icons/collapse-icon.svg" style={{ marginLeft: '6px', display: props.isOpen ? 'block' : 'none' }} />
                </div>

                {subtotalDisplay != '$0' &&
                    <div className="float-right">
                        {subtotalDisplay} <span style={{ fontSize: '0.6rem', textTransform: 'uppercase' }}>per Month</span>
                    </div>
                }
                
            </CardHeader>
            <Collapse
                isOpen={props.isOpen}
                onEntered={onEntered}
                onExited={onExited}
            >
                <CardBody>
                    {props.category.expenseItemTypes.map(t =>

                        <FormGroup row key={t.code}>
                            <Label for={'amount_' + t.code} sm={6} xs={12} size="sm">{t.name}</Label>
                            <Col sm={3} xs={6}>
                                <Input type="number" name={t.code} id={'amount_' + t.code} placeholder="" bsSize="sm" onChange={handleAmountUpdate} />
                            </Col>
                            <Col sm={3} xs={6}>
                                <Input type="select" name={t.code} id={'frequency_' + t.code} onChange={handleFrequencyUpdate} bsSize="sm" defaultValue={"Monthly"}>
                                    <option>Annually</option>
                                    <option>Quarterly</option>
                                    <option>Monthly</option>
                                    <option>Fortnightly</option>
                                    <option>Weekly</option>
                                </Input>
                            </Col>
                        </FormGroup>
                    )}            
                </CardBody>
            </Collapse>  
        </Card>                 
    )

}
