import React, { useState, useMemo } from 'react';
import { Collapse, Card, CardBody, CardHeader, FormGroup, Label, Col, Input } from 'reactstrap';
import { calcMonthly } from '../util/Conversions';
import './IncomeCategoryItem.css';

export const IncomeCategoryItem = (props) => {

    const handleClick = () => {
        props.onToggle(props.category.code);
    }

    let initialValues = {};
    props.category.incomeItemTypes.forEach(t => {
        initialValues[t.code] = {
            amount: 0,
            frequency: 'Monthly'
        }
    });

    const [itemValues, setItemValues] = useState(initialValues);
    

    const onEntered = () => {
        props.onTogglePanelComplete();
    };

    const onExited = () => {
        props.onTogglePanelComplete();
    };

    const handleAmountUpdate = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        const itemValuesClone = { ...itemValues };
        itemValuesClone[nam].amount = Number(val);
        setItemValues(itemValuesClone);

        props.onUpdate(props.category.code, itemValuesClone);
    }

    const handleFrequencyUpdate = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        const itemValuesClone = { ...itemValues };
        itemValuesClone[nam].frequency = val;
        setItemValues(itemValuesClone);

        props.onUpdate(props.category.code, itemValuesClone);
    }


    const subtotalDisplay = useMemo(() => {

        let total = 0;
        for (const [key, value] of Object.entries(itemValues)) {
            total = total + calcMonthly(value.frequency, Number(value.amount));
        }

        return `$${Math.round(total)}`;

    }, [itemValues]);

    return (

        <Card className="income-category-item">
            <CardHeader onClick={handleClick} className={'budget-item-header'} >
                <div className="float-left">
                    <h3 style={{ marginBottom: 0 }}>{props.category.name}</h3>
                    <div>
                        <div style={{ fontSize: '2rem', marginTop: '3px'  }}>{subtotalDisplay}</div>
                        <div style={{ fontSize: '0.7rem', lineHeight: '1.3rem', textTransform: 'uppercase' }}> per month</div>
                    </div>     
                </div>
         
                <div className="float-right">
                    <img alt="" src="./icons/expand-icon.svg" style={{ width: '49px', marginTop: '14px', display: props.isOpen ? 'none' : 'block' }} />
                    <img alt="" src="./icons/collapse-icon.svg" style={{ width: '49px', marginTop: '14px', display: props.isOpen ? 'block' : 'none' }} />
                </div>
                
            </CardHeader>
            <Collapse
                isOpen={props.isOpen}
                onEntered={onEntered}
                onExited={onExited}
            >
                <CardBody>
                    {props.category.incomeItemTypes.map(t =>

                        <FormGroup row key={t.code}>
                            <Label for={'amount_' + t.code} sm={6} xs={12} size="sm">{t.name}</Label>
                            <Col sm={3} xs={6}>
                                <Input type="number" name={t.code} id={'amount_' + t.code} placeholder="" bsSize="sm" onChange={handleAmountUpdate} />
                            </Col>
                            <Col sm={3} xs={6}>
                                <Input type="select" name={t.code} id={'frequency_' + t.code} onChange={handleFrequencyUpdate} bsSize="sm" defaultValue={"Monthly"}>
                                    <option>Annually</option>
                                    <option>Quarterly</option>
                                    <option>Monthly</option>
                                    <option>Fortnightly</option>
                                    <option>Weekly</option>
                                </Input>
                            </Col>
                        </FormGroup>
                    )}            
                </CardBody>
            </Collapse>  
        </Card>                 
    )

}
