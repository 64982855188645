import React from 'react';

export const Details = (props) => {

    return (
        <div className="submission-details-display">
            <div className="heading-panel">
                <h3>Enquiry From</h3>
                <div><label>Name:</label><div>{props.details.name}</div></div>
            </div>
            <div className="body-panel">               
                <div><label>Email:</label><div>{props.details.email}</div></div>
                <div><label>Phone:</label><div>{props.details.phone}</div></div>
                <div><label>Current Home Value:</label><div>${props.details.currentHomeValue}</div></div>
                <div><label>Home Loan Balance:</label><div>${props.details.homeLoanBalanceOwing}</div></div>
                <div><label>Home Loan Interest Rate:</label><div>{props.details.homeLoanInterestRate}%</div></div>
                <div><label>Home Loan Type:</label><div>{props.details.homeLoanType}</div></div>
            </div>
        </div>
    )

}